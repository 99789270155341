import { React, useRef, useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import Header from "./Header";
import Footer from "./Footer";
import HeaderLower from "./HeaderLower";
import swal from "sweetalert";
// import Recaptcha from "react-recaptcha";
import LogService from "../services/log.service";
import RegisterService from "../services/register.service";

function RegisterForm(props) {
  const [fields, setFields] = useState({
    sales_name: "",
    name: "",
    lname: "",
    emailid: "",
    showroom: "",
    address: "",
    phone: "",
    question: "",
  });

  const [errors, setErrors] = useState({});
  const [captcha, setCaptcha] = useState(false);
  const [value_userform, setValue_userform] = useState([]);

  const [website, setWebsite] = useState(window.location.href);

  useEffect(() => {
    async function logCreate() {
      try {
        await LogService.createLog(website);
      } catch (e) {
        console.log(e);
      }
    }
    logCreate();
  }, [website]);

  localStorage.setItem("isRegister", false);
  const ref = useRef();
  useEffect(() => {
    test();
  }, []);

  async function test() {
    let errors = {};

    try {
      //FIXME remove this service if not used
      const response = await RegisterService.getStates();
      var value = response.data.data;
      this.setState({
        value: value,
      });
      if (response.data.status === 429 || response.data.status === "429") {
        alert("Max Limit Reached. Please wait.");
      }
      if (value.includes("redirect")) {
      } else {
        errors["questionnare"] = response.data.response;
      }
    } catch (err) {
      if (err.status === 429 || err.status === "429") {
        alert("Max Limit Reached. Please wait.");
      }
    }
  }

  const pasted = (e) => {
    e.preventDefault();
    return false;
  };

  const onlyNumbers = (evt) => {
    const { name, value } = evt.target;
    evt.target.value = evt.target.value.replace(/[^0-9]+/g, "");

    setFields((prevValue) => {
      return {
        ...prevValue,
        [name]: evt.target.value,
      };
    });
  };

  const checkedValue = (e) => {
    let value = "yes";
    if (e === false || e === "") {
      value = "no";
    }
    return value;
  };

  const handleChange = (e) => {
    let { value, checked, name } = e.target;
    if (name === "showroom" && value === "true") {
      value = "";
    }
    if (name !== "question") {
      setFields((prevValue) => {
        return {
          ...prevValue,
          [name]: value,
        };
      });
    } else {
      setFields((prevValue) => {
        return {
          ...prevValue,
          [name]: checked,
        };
      });
    }
  };

  const saveErrors = (err) => {
    setErrors(err);
  };

  // const verifyCallback = (response) => {
  //   let errors = {};
  //   if (response) {
  //     setCaptcha({
  //       captcha: true,
  //     });
  //   } else {
  //     setErrors(errors);
  //     return false;
  //   }
  // };

  const submituserRegistrationForm = (e) => {
    e.preventDefault();
    if (validate()) {
      let data = {
        sales_name: fields?.sales_name,
        fname: fields?.name,
        lname: fields?.lname,
        email: fields?.emailid,
        showroom: fields?.showroom,
        address: fields?.address,
        phone: fields?.phone,
        question: checkedValue(fields?.question),
        reference: window.location.pathname.replace("/", ""),
      };

      var username = process.env.REACT_APP_API_USERNAME;
      var password = process.env.REACT_APP_API_PASSWORD;
      try {
        try {
          LogService.createFormLog(website, "POST", data);
        } catch (e) {
          console.log(e);
        }

        const responseuserform = RegisterService.regData(data);
        responseuserform
          .then((response) => {
            LogService.apiResLog(website, JSON.stringify(response.data));

            var valueUserform = response?.data?.status;
            setValue_userform(valueUserform);
            localStorage.removeItem("isRegister");
            swal({
              title: "Successful!",
              text: "Form Submitted!",
              type: "success",
              icon: "success",
            }).then(() => {
              props.history.push({
                pathname: "/TestOurTech_thankyou",
                state: {
                  sales_name: fields?.sales_name,
                  name: fields.name,
                  lname: fields.lname,
                  emailid: fields.emailid,
                  showroom: fields.showroom,
                  address: fields?.address,
                  phone: fields?.phone,
                  question: fields?.question,
                  reference: window.location.pathname.replace("/", ""),
                },
              });
            });
            if (value_userform?.includes("redirect")) {
            }
          })
          .catch((err) => {
            LogService.apiResLog(website, JSON.stringify(err.response.data));

            let errorData = {};
            errorData["sales_name"] =
              err?.response?.data?.message &&
              err?.response?.data?.message.sales_name &&
              err?.response?.data?.message.sales_name[0];
            errorData["name"] =
              err?.response?.data?.message &&
              err?.response?.data?.message.fname &&
              err?.response?.data?.message.fname[0];
            errorData["lname"] =
              err?.response?.data?.message &&
              err?.response?.data?.message.lname &&
              err?.response?.data?.message.lname[0];
            errorData["emailid"] =
              err?.response?.data?.message &&
              err?.response?.data?.message.email &&
              err?.response?.data?.message.email[0];
            errorData["showroom"] =
              err?.response?.data?.message &&
              err?.response?.data?.message.showroom &&
              err?.response?.data?.message.showroom[0];
            errorData["address"] =
              err?.response?.data?.message &&
              err?.response?.data?.message.address &&
              err?.response?.data?.message.address[0];
            errorData["phone"] =
              err?.response?.data?.message &&
              err?.response?.data?.message.phone &&
              err?.response?.data?.message.phone[0];
            errorData["checkederrortwo"] =
              err?.response?.data?.message &&
              err?.response?.data?.message.size &&
              err?.response?.data?.message.size[0];
            saveErrors(errorData);
            ref?.current?.focus();
          });
      } catch (err) {
        if (err.response.status == 429 || err.response.status == "429") {
          alert("Max Limit Reached. Please wait.");
        }
        let errorData = {};
        errorData["sales_name"] = err?.response?.data?.message.sales_name[0];
        errorData["name"] = err?.response?.data?.message.fname[0];
        errorData["lname"] = err?.response?.data?.message.lname[0];
        errorData["emailid"] = err?.response?.data?.message.emailid[0];
        errorData["showroom"] = err?.response?.data?.message.showroom[0];
        errorData["address"] = err?.response?.data?.message.address[0];
        errorData["phone"] = err?.response?.data?.message.phone[0];

        saveErrors(errorData);
      }
    }
  };
  const validate = (e) => {
    let errors = {};
    let isformIsValid = true;
    // if (!captcha) {
    //   isformIsValid = false;
    //   errors["checkederrorcaptcha"] = "Captcha not verified";
    //   saveErrors(errors);
    //   return isformIsValid;
    // }
    // if (!i_agree) {
    //   isformIsValid = false;
    //   errors["checkederror"] = "Select the checkbox";
    //   setErrors(errors);

    //   return false;
    // }

    return isformIsValid;
  };
  // const onLoadRecaptcha = () => {};

  return (
    <>
      <div className="container-fluid">
        <Header />
        <HeaderLower />
        <div id="main-registration-container">
          <div className="white-box form">
            {/* <h1>Register to score your free Reward Card!</h1>
            <hr /> */}
            <form
              name="userRegistrationForm"
              className="form-outer"
              onSubmit={submituserRegistrationForm}
            >
              <div className="row">
                <div className="col-md-6">
                  <label className="formsub">
                    Customer First Name <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Customer First Name"
                    style={errors.name ? { border: "1px solid red" } : null}
                    name="name"
                    id="fname"
                    className="searchBox_deals vc-validate"
                    ref={errors.name ? ref : null}
                    value={fields.name || ""}
                    autoFocus=""
                    onChange={handleChange}
                  />
                  <div className="errorMsg">{errors.name}</div>
                </div>
                <div className="col-md-6">
                  <label className="formsub">
                    Customer Last Name <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    name="lname"
                    style={
                      errors.lname && !errors.name
                        ? { border: "1px solid red", color: "black" }
                        : { color: "black" }
                    }
                    placeholder="Customer Last Name"
                    id="lname"
                    className="searchBox_deals vc-validate"
                    value={fields.lname || ""}
                    ref={errors.lname && !errors.name ? ref : null}
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {errors.lname && !errors.name ? errors.lname : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <label className="formsub">
                    Customer Email <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    name="emailid"
                    ref={
                      errors.emailid && !errors.lname && !errors.name
                        ? ref
                        : null
                    }
                    style={
                      errors.emailid && !errors.lname && !errors.name
                        ? { border: "1px solid red" }
                        : null
                    }
                    id="emailid"
                    placeholder="Customer Email"
                    value={fields.emailid || ""}
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {errors.emailid && !errors.lname && !errors.name
                      ? errors.emailid
                      : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <label className="formsub">
                    Customer Phone# <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    id="phone"
                    style={
                      errors.phone &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name
                        ? { border: "1px solid red" }
                        : null
                    }
                    name="phone"
                    placeholder="Customer Phone"
                    value={fields.phone || ""}
                    ref={
                      errors.phone &&
                      !errors.emailid &&
                      !errors.address &&
                      !errors.lname &&
                      !errors.name
                        ? ref
                        : null
                    }
                    onChange={handleChange}
                    onKeyUp={onlyNumbers}
                    onPaste={pasted}
                  />
                  <div className="errorMsg">
                    {errors.phone &&
                    !errors.emailid &&
                    !errors.lname &&
                    !errors.name
                      ? errors.phone
                      : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <label required className="formsub">
                    Customer Address <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    name="address"
                    placeholder="Customer Address"
                    id="address"
                    className="searchBox_deals vc-validate"
                    autoFocus=""
                    value={fields.address || ""}
                    onChange={handleChange}
                    style={
                      errors.address &&
                      !errors.phone &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name
                        ? { border: "1px solid red", color: "black" }
                        : { color: "black" }
                    }
                    ref={
                      errors.address &&
                      !errors.phone &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name
                        ? ref
                        : null
                    }
                  />
                  <div className="errorMsg">
                    {errors.address &&
                    !errors.phone &&
                    !errors.emailid &&
                    !errors.lname &&
                    !errors.name
                      ? errors.address
                      : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <label className="formsub">
                    Showroom Location <span className="red_point">*</span>
                  </label>

                  <select
                    style={
                      errors.showroom &&
                      !errors.address &&
                      !errors.phone &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name
                        ? { border: "1px solid red", color: "black" }
                        : { color: "black" }
                    }
                    ref={
                      errors.showroom &&
                      !errors.address &&
                      !errors.phone &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name
                        ? ref
                        : null
                    }
                    name="showroom"
                    id="showroom"
                    className="select_1 dropdown"
                    onChange={handleChange}
                  >
                    <option value>Select Showroom Location</option>

                    {window.location.pathname.replace("/", "") === "" ? (
                      <>
                        <option value="Alexandria">Alexandria</option>
                        <option value="Bear">Bear</option>
                        <option value="Bensalem">Bensalem</option>
                        <option value="Brick">Brick</option>
                        <option value="Burlington">Burlington</option>
                        <option value="Capitol Heights">Capitol Heights</option>
                        <option value="Catonsville">Catonsville</option>
                        <option value="Chambersburg">Chambersburg</option>
                        <option value="Churchville">Churchville</option>
                        <option value="Columbia">Columbia</option>
                        <option value="Cottman Avenue">Cottman Avenue</option>
                        <option value="Dover">Dover</option>
                        <option value="Downingtown">Downingtown</option>
                        <option value="Easton">Easton</option>
                        <option value="Eatontown">Eatontown</option>
                        <option value="Fairfax">Fairfax</option>
                        <option value="Falls Church">Falls Church</option>
                        <option value="Frederick">Frederick</option>
                        <option value="Freehold">Freehold</option>
                        <option value="Gaithersburg">Gaithersburg</option>
                        <option value="Glen Burnie">Glen Burnie</option>
                        <option value="Green Brook">Green Brook</option>
                        <option value="Hagerstown">Hagerstown</option>
                        <option value="Harrisburg">Harrisburg</option>
                        <option value="Hunt Valley">Hunt Valley</option>
                        <option value="Lancaster">Lancaster</option>
                        <option value="Ledgewood">Ledgewood</option>
                        <option value="Lexington Park">Lexington Park</option>
                        <option value="Linden">Linden</option>
                        <option value="Manahawkin">Manahawkin</option>
                        <option value="Maple Shade">Maple Shade</option>
                        <option value="Mechanicsburg">Mechanicsburg</option>
                        <option value="Middletown">Middletown</option>
                        <option value="Montgomeryville">Montgomeryville</option>
                        <option value="North Brunswick">North Brunswick</option>
                        <option value="Pasadena">Pasadena</option>
                        <option value="Phillipsburg">Phillipsburg</option>
                        <option value="Pottstown">Pottstown</option>
                        <option value="Quakertown">Quakertown</option>
                        <option value="Rosedale">Rosedale</option>
                        <option value="Staten Island">Staten Island</option>
                        <option value="Toms River">Toms River</option>
                        <option value="Waldorf">Waldorf</option>
                        <option value="Warminster">Warminster</option>
                        <option value="Whitehall">Whitehall</option>
                        <option value="Wilmington">Wilmington</option>
                        <option value="Wind Gap">Wind Gap</option>
                        <option value="York">York</option>
                      </>
                    ) : window.location.pathname
                        ?.replace("/", "")
                        ?.toLowerCase() === "bostonfam" ? (
                      <>
                        <option value="Appleton">Appleton</option>
                        <option value="Green Bay">Green Bay</option>
                        <option value="Greenfield">Greenfield</option>
                        <option value="Janesville">Janesville</option>
                        <option value="Kenosha">Kenosha</option>
                        <option value="Madison">Madison</option>
                        <option value="Marshfield">Marshfield</option>
                        <option value="Oshkosh">Oshkosh</option>
                        <option value="Pewaukee">Pewaukee</option>
                        <option value="Plover">Plover</option>
                        <option value="Rhinelander">Rhinelander</option>
                        <option value="Richfield">Richfield</option>
                        <option value="Stevens Point">Stevens Point</option>
                        <option value="Wausau">Wausau</option>
                      </>
                    ) : window.location.pathname
                        ?.replace("/", "")
                        ?.toLowerCase() === "morris" ? (
                      <>
                        <option value="Ashley Outlet - Brice Road/Reynoldsburg">
                          Ashley Outlet - Brice Road/Reynoldsburg
                        </option>
                        <option value="Ashley Store - Beavercreek">
                          Ashley Store - Beavercreek
                        </option>

                        <option value="Ashley Store - Cincinnati/Tri-County">
                          Ashley Store - Cincinnati/Tri-County
                        </option>
                        <option value="Ashley Store - Cincinnati/Florence">
                          Ashley Store - Cincinnati/Florence
                        </option>
                        <option value="Ashley Store - Colerain/Cincinnati">
                          Ashley Store - Colerain/Cincinnati
                        </option>

                        <option value="Ashley Store - Columbus/Sawmill">
                          Ashley Store - Columbus/Sawmill
                        </option>
                        <option value="Ashley Store - Columbus/Polaris">
                          Ashley Store - Columbus/Polaris
                        </option>

                        <option value="Ashley Store - Dayton/Mall Wood">
                          Ashley Store - Dayton/Mall Wood
                        </option>

                        <option value="Ashley Store - Huber Heights">
                          Ashley Store - Huber Heights
                        </option>
                        <option value="Ashley Store - Mason">
                          Ashley Store - Mason
                        </option>

                        <option value="Ashley Store - Reynoldsburg/Pickerington">
                          Ashley Store - Reynoldsburg/Pickerington
                        </option>

                        <option value="Ashley Store - Tuttle">
                          Ashley Store - Tuttle
                        </option>
                        <option value="Morris Home - Centerville">
                          Morris Home - Centerville
                        </option>

                        <option value="Morris Home - Cincinnati/Tri-County">
                          Morris Home - Cincinnati/Tri-County
                        </option>
                        <option value="Morris Home - Columbus">
                          Morris Home - Columbus
                        </option>
                        <option value="Morris Home - Dayton Mall">
                          Morris Home - Dayton Mall
                        </option>

                        <option value="Morris Home - Florence">
                          Morris Home - Florence
                        </option>
                        <option value="Morris Home - Mall at Fairfield Commons">
                          Morris Home - Mall at Fairfield Commons
                        </option>
                        <option value="Morris Home - Polaris">
                          Morris Home - Polaris
                        </option>
                        <option value="Morris Home - Tuttle">
                          Morris Home - Tuttle
                        </option>
                        <option value="Morris Outlet - Cincinnati/Tri-County">
                          Morris Outlet - Cincinnati/Tri-County
                        </option>

                        <option value="Morris Outlet - Fairborn">
                          Morris Outlet - Fairborn
                        </option>
                      </>
                    ) : null}
                  </select>
                  <div className="errorMsg">
                    {errors.showroom &&
                    !errors.address &&
                    !errors.phone &&
                    !errors.emailid &&
                    !errors.lname &&
                    !errors.name
                      ? errors.showroom
                      : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <label className="formsub">
                    Sales Associate Name <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Sale Associate Name"
                    style={
                      errors.sales_name &&
                      !errors.showroom &&
                      !errors.address &&
                      !errors.phone &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name
                        ? { border: "1px solid red", color: "black" }
                        : { color: "black" }
                    }
                    name="sales_name"
                    id="sales_name"
                    className="searchBox_deals vc-validate"
                    ref={
                      errors.sales_name &&
                      !errors.showroom &&
                      !errors.address &&
                      !errors.phone &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name
                        ? ref
                        : null
                    }
                    value={fields.sales_name || ""}
                    autoFocus=""
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {errors.sales_name &&
                    !errors.showroom &&
                    !errors.address &&
                    !errors.phone &&
                    !errors.emailid &&
                    !errors.lname &&
                    !errors.name
                      ? errors.sales_name
                      : null}
                  </div>
                </div>
                <br />

                {window.location.pathname.replace("/", "") === "" ? (
                  <li>
                    <label>
                      <input
                        type="checkbox"
                        id="question"
                        name="question"
                        className="conditions"
                        value={fields.question}
                        onChange={handleChange}
                      />
                      <span>
                        {" "}
                        Sign up to receive Email and SMS updates from R&S
                        Distributing LLC dba Ashley and Purple.
                      </span>
                    </label>
                  </li>
                ) : null}

                {/* <br />
                <br />
                <div className="overflow-hider">
                  <Recaptcha
                    sitekey={process.env.REACT_APP_API_GOOGLESITEKEY}
                    render="explicit"
                    verifyCallback={verifyCallback}
                    onloadCallback={onLoadRecaptcha}
                  />
                </div>
                <div className="errorMsg">{errors.checkederrorcaptcha}</div> */}

                <br />
                <div className="clearfix"></div>
              </div>

              <div className="alert alert-info">
                Limit 1 per household, while supplies last. Some restrictions
                apply, see store for detail.
              </div>
              <input
                type="submit"
                name="submit"
                id="submit"
                value="SUBMIT"
                className="sub-btn1 button Formfirst"
              />
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default RegisterForm;
