import React from "react";
import ReactDOM from "react-dom";
import registerServiceWorker from "./registerServiceWorker";
import HttpsRedirect from "react-https-redirect";
import { Helmet } from "react-helmet";
import App from "./App";


ReactDOM.render(
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Purple Sweepstakes</title>
    </Helmet>
    <HttpsRedirect>
      <App />
    </HttpsRedirect>
  </>,
  document.getElementById("root")
);
registerServiceWorker();
