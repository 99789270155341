import React from "react";
import purpleLogo from "../Images/purples-logo.jpg";
import purpleLogo2 from "../Images/purples-logo-2.png";
const Header = () => {
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="logo">
          <img
            src={
              window.location.pathname.replace("/", "") === ""
                ? purpleLogo
                : purpleLogo2
            }
            className="img-responsive"
          />
        </div>
      </div>
    </div>
  );
};
export default Header;
